import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Holiday, Absence, User, Delegate } from '../../Types';
import './Profile.css';
import dropArrow from '../../assets/Icons/CalendarIcons/CalendarUI/resultDropArrow.svg';
import { Typography } from '@mui/material';
import AbsenceFormPopup from '../AbsenceForm/AbsenceFormPopUp';
import { getLogin } from '../../helpers/authHelper';
import { formatDate, getBackgroundColor, getBackgroundColorWithStatus } from '../../helpers/absenceRequests';
import { getHolidayBalance } from '../../helpers/holidays';
import pattern from '../../assets/Icons/LeadIcons/pattern.png';
import { getSickDaysForCurrentYear } from '../../helpers/sickDays';

interface ProfileProps {
  groups?: string[];
}

declare module 'react' {
  // allow CSS custom properties
  interface CSSProperties {
    [varName: `--${string}`]: string | number | undefined;
  }
}

const Profile = (props: ProfileProps) => {
  const { groups } = props;
  const [totalHolidays, setTotalHolidays] = useState<number | null>(null);
  const [userProfile, setUserProfile] = useState<User | null>(null);
  const [delegates, setDelegates] = useState<Delegate[]>([]);
  const [pendingRequests, setPendingRequests] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [personalAbsences, setPersonalAbsences] = useState<Absence[]>([]);
  const [sickDays, setSickDays] = useState<number>();
  const [, setFetchingPersonalAbsences] =
    useState<boolean>(false);
  const [historyAbsences, setHistoryAbsences] = useState<Absence[]>([]);
  const [absenceFormOpen, setAbsenceFormOpen] = useState(false);
  const [isPendingRequestsOpen, setIsPendingRequestsOpen] = useState(true);
  const [isCurrentAndFutureOpen, setIsCurrentAndFutureOpen] = useState(true);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [assigningDelegate, setAssigningDelegate] = useState(true);

  const msal = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleAbsenceFormOpen = () => {
    setAbsenceFormOpen(true);
  };

  const handleAbsenceFormClose = () => {
    setAbsenceFormOpen(false);
  };

  const getStrokeColor = useCallback((): CSSProperties => {
    if ((totalHolidays || 0) < 1) {
      return {stroke: '#ebebeb'};
    }
    return (totalHolidays || 0) > 25 ? {stroke: '#bf0629'} : {stroke: '#7ba47d'};
  },[totalHolidays]);

  const fetchPendingRequests = useCallback(async () => {
    try {
      const login = await getLogin(msal.instance);

      const response = await axios.get<any[]>(
        `${process.env.REACT_APP_BACKEND_BASEURL}/personal-requests`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${login.idToken}`,
          },
        }
      );

      const sortedRequests = response.data.sort((a, b) =>
        new Date(a.startDate) > new Date(b.startDate) ? 1 : -1
      );

      setPendingRequests(sortedRequests);
    } catch (error) {
      console.error('Error fetching personal requests:', error);
    }
  },[msal.instance]);

  const fetchCurrentAndFutureAbsences = useCallback(async () => {
    try {
      const login = await getLogin(msal.instance);

      setFetchingPersonalAbsences(true);

      const response = await axios.get<Absence[]>(
        `${process.env.REACT_APP_BACKEND_BASEURL}/personal-absences`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${login.idToken}`,
          },
        }
      );

      const currentDate = new Date();
      currentDate.setUTCHours(0, 0, 0, 0);

      const currentAndFutureAbsences: Absence[] = response.data
        .filter((absence: Absence) => {
          const startDate = new Date(absence.startDate);
          const endDate = new Date(absence.endDate);
          startDate.setUTCHours(0, 0, 0, 0);
          endDate.setUTCHours(0, 0, 0, 0);
          return (startDate >= currentDate || (startDate < currentDate && endDate >= currentDate)) &&
            absence.status === 'Approved' &&
            absence.deleted === false;
        })
        .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

      const historyAbsences: Absence[] = response.data
        .filter((absence: Absence) => {
          const endDate = new Date(absence.endDate);
          endDate.setUTCHours(0, 0, 0, 0);
          return (absence.status === 'Approved' && endDate < currentDate) ||
            absence.status === 'Declined' ||
            absence.deleted === true;
        })
        .sort((a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime());

      setSickDays(await getSickDaysForCurrentYear(response.data, msal.instance));

      setPersonalAbsences(currentAndFutureAbsences);
      setHistoryAbsences(historyAbsences);
      setFetchingPersonalAbsences(false);
    } catch (error) {
      console.error('Error fetching personal absences:', error);
      setFetchingPersonalAbsences(false);
    }
  },[msal.instance]);

  const fetchUserProfile = useCallback(async () => {
    try {
      const login = await getLogin(msal.instance);

      const response = await axios.get<User[]>(
        `${process.env.REACT_APP_BACKEND_BASEURL}/users`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${login.idToken}`,
          },
        }
      );

      const user = response.data.find((user) => user.userId === msal.instance.getActiveAccount()?.idTokenClaims?.onprem_sid);

      if (user === undefined) {
        return;
      }

      const startDate = user.description;

      //start date from the AD is "yyyy-mm-dd" I converted it to "dd.mm.yyyy"
      const parts = startDate.split('-');
      const formattedStartDate = `${parts[2]}.${parts[1]}.${parts[0]}`;

      setStartDate(formattedStartDate);

      setUserProfile(user);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  },[msal.instance]);

  const togglePendingRequests = () => {
    setIsPendingRequestsOpen(!isPendingRequestsOpen);
  };

  const toggleCurrentAndFuture = () => {
    setIsCurrentAndFutureOpen(!isCurrentAndFutureOpen);
  };

  const toggleHistory = () => {
    setIsHistoryOpen(!isHistoryOpen);
  };

  useEffect(() => {
    if (isAuthenticated === true) {
      fetchUserProfile();
      fetchPendingRequests();
      fetchCurrentAndFutureAbsences();
    }
  }, [isAuthenticated, msal.instance, fetchPendingRequests, fetchCurrentAndFutureAbsences, fetchUserProfile]);

  useEffect(() => {
    if (userProfile !== null) {
      const fetchHolidays = async () => {
        try {
          const login = await getLogin(msal.instance);
    
          const response = await axios.get<Holiday[]>(
            `${process.env.REACT_APP_BACKEND_BASEURL}/holidays`,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${login.idToken}`,
              },
            }
          );

          try {
            const holidays = response.data.filter((holiday) => holiday.employeeUserId === msal.instance.getActiveAccount()?.idTokenClaims?.onprem_sid);

            setTotalHolidays(getHolidayBalance(msal.instance.getActiveAccount()?.idTokenClaims?.onprem_sid as string, holidays));
          } catch(e) {
            setTotalHolidays(0);
          }
        } catch (error) {
          console.error('Error fetching holidays:', error);
        }
      };

      fetchHolidays();
    }
  },[userProfile, msal.instance]);

  useEffect(() => {
    if (groups?.includes(process.env.REACT_APP_LEAD_GROUP as string)) {
      const getDelegates = async() => {
        setAssigningDelegate(true);
        try {
          const login = await getLogin(msal.instance);

          const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASEURL}/delegates`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${login.idToken}`
            }
          });

          setDelegates(response.data);
        } catch(error) {
          console.error('Error fetching delegates:', error);
        }
        setAssigningDelegate(false);
      };
      getDelegates();
    }
  }, [msal.instance, groups]);

  const assignDelegate = useCallback(async(delegateUserId: string) => {
    setAssigningDelegate(true);
    try {
      const login = await getLogin(msal.instance);

      await axios.post(`${process.env.REACT_APP_BACKEND_BASEURL}/delegates`, {
        delegateUserId
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${login.idToken}`
        }
      });
    } catch(error) {
      console.error(`Error assigning Delegate ${error}`);
    }
    await fetchUserProfile();
    setAssigningDelegate(false);
  },[msal.instance, fetchUserProfile]);

  return (
    <React.Fragment>
      <div className='container'>
        <div className='left-column'>
          <div className='profile-container'>
            <div className='left-profile'>
              <div className='name'>
                <Typography variant='h6' className='welcome'>
                  {userProfile?.name}{' '}
                </Typography>
              </div>
              <h3 className='job-title'>{userProfile?.title || 'title..'}</h3>
            </div>
            <div className='profile-right-side'>
              <h3 className='profile-info-header'>
                Department
                <span className='sub-info'>
                  {userProfile?.department || 'fetch..'}{' '}
                </span>
              </h3>
              <h3 className='profile-info-header'>
                Lead
                <span className='sub-info'>
                  {userProfile?.leadName || 'fetch..'}
                </span>
              </h3>
              <h3 className='profile-info-header'>
                Starting Date
                <span className='sub-info'>{startDate || 'fetch..'} </span>
              </h3>
              <h3 className='profile-info-header'>
                Location
                <span className='sub-info'>{userProfile?.co || 'fetch..'}</span>
              </h3>
              {groups && groups.includes(process.env.REACT_APP_LEAD_GROUP as string) &&
              <React.Fragment>
                <h3 className='profile-info-header'>
                  Delegate
                  <select
                    onChange={(e) => {
                      const { value } = e.target;
                      assignDelegate(value);
                    }}
                    value={userProfile?.delegateUserId || ''}
                    disabled={assigningDelegate}
                  >
                    <option value=''>No Delegate Set</option>
                    {delegates.map((delegate) => <option key={delegate.userId} value={delegate.userId}>{delegate.name}</option>)}
                  </select>
                </h3>
              </React.Fragment>
              }
            </div>
          </div>
          <div className='holiday-container'>
            <h3 className='holiday-title'>Vacation Days until 31.12</h3>
            <div className='holiday-circle-container'>
              <svg
                width='152'
                height='152'
                viewBox='0 0 120 120'
                className='circular-progress'
                style={{'--progress': ((totalHolidays || 0)/25)*100}}
              >
                <circle className='bg'></circle>
                <circle className='fg' style={getStrokeColor()}></circle>
              </svg>
              <div className='holiday-amount'>
                {totalHolidays ?? 'loading...'}
              </div>
            </div>
            <div className='button-container'>
              <button
                className='request-button'
                style={{ width: '243px' }}
                onClick={handleAbsenceFormOpen}
              >
                Request Absence
              </button>
            </div>
          </div>
          <div className='holiday-container'>
            <h3 className='holiday-title'>Sick Days Taken This Year</h3>
            <div className='holiday-circle-container'>
              <div className='holiday-circle'>
                <div className='holiday-amount'>
                  {sickDays ?? 'loading...'}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='right-column'>
          <div className='request-container'>
            <h3 className='requests-title'> My Requests </h3>
            <div className='request-container-content'>
              <h2 className='request-titles' onClick={togglePendingRequests}>
                <img
                  src={dropArrow}
                  alt='Open Arrow'
                  style={{
                    width: '22px',
                    height: '22px',
                    transform: isPendingRequestsOpen ? 'rotate(0deg)' : 'rotate(-90deg)'
                  }}
                />
                Open Requests ({pendingRequests.length})
              </h2>
              <div>
                {isPendingRequestsOpen && pendingRequests.map((request, index) => {
                  const { backgroundColor, icon } = getBackgroundColor(
                    request.reason
                  );
                  const startDate = formatDate(new Date(request.startDate));
                  const endDate = formatDate(new Date(request.endDate));

                  return (
                    request.status === 'Requested' && (
                      <div key={index} className='absence-container' title={`${request.reason}${request.information === '' ? '' : ' - '}${request.information}`}>
                        <div
                          className='absence-block'
                          style={{
                            backgroundColor: backgroundColor,
                          }}
                        >
                          <div className='pattern-overlay' style={{ backgroundImage: `url(${pattern})` }}></div>
                          <div className='absence-info'>
                            {icon && (
                              <img
                                src={icon}
                                alt={request.reason}
                                className='absence-icon'
                              />
                            )}
                            <p className='absence-date'>
                              {startDate === endDate ? startDate : `${startDate} - ${endDate}`}
                            </p>
                          </div>
                        </div>
                        <div className='absence-status' style={{backgroundImage: 'none'}}>
                          <p className='status' style={{ color: backgroundColor }}>Requested</p>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>

              <h2 className='request-titles' onClick={toggleCurrentAndFuture}>
                <img
                  src={dropArrow}
                  alt='Dropdown Arrow'
                  style={{
                    width: '22px',
                    height: '22px',
                    transform: isCurrentAndFutureOpen ? 'rotate(0deg)' : 'rotate(-90deg)'
                  }}
                />
                Current And Future ({personalAbsences?.length})
              </h2>
              <div>
                {isCurrentAndFutureOpen && personalAbsences.map((absence, index) => {
                  const { backgroundColor, icon } = getBackgroundColorWithStatus(absence);
                  const currentDate = new Date();
                  currentDate.setUTCHours(0, 0, 0, 0);
                  const startDate = new Date(absence.startDate);
                  startDate.setUTCHours(0, 0, 0, 0);
                  const endDate = new Date(absence.endDate);
                  endDate.setUTCHours(0, 0, 0, 0);

                  const formattedStartDate = formatDate(new Date(absence.startDate));
                  const formattedEndDate = formatDate(new Date(absence.endDate));

                  let statusText = '';
                  if (startDate <= currentDate && endDate >= currentDate) {
                    if (absence.reason === 'Sick Child') {
                      statusText = `${absence.reason}`;
                    } else {
                      statusText = `On ${absence.reason}`;
                    }
                  } else if (startDate > currentDate) {
                    statusText = 'Planned';
                  }
                  if (absence.status === 'Declined') {
                    statusText = 'Declined';
                  }

                  return (
                    <div key={index} className='absence-container' title={`${absence.reason}${absence.information === '' ? '' : ' - '}${absence.information}`}>
                      <div
                        className='absence-block'
                        style={{
                          backgroundColor: backgroundColor,
                        }}
                      >
                        <div className='absence-info'>
                          {icon && (
                            <img
                              src={icon}
                              alt={absence.reason}
                              className='absence-icon'
                            />
                          )}
                          <p className='absence-date'>
                            {formattedStartDate === formattedEndDate ? formattedStartDate : `${formattedStartDate} - ${formattedEndDate}`}
                          </p>
                        </div>
                      </div>
                      <div className='absence-status'>
                        <p className='status' style={{ color: backgroundColor }}>{statusText}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <h2 className='request-titles' onClick={toggleHistory}>
                <img
                  src={dropArrow}
                  alt='Dropdown Arrow'
                  style={{
                    width: '22px',
                    height: '22px',
                    transform: isHistoryOpen ? 'rotate(0deg)' : 'rotate(-90deg)'
                  }}
                />
                History ({historyAbsences?.length})
              </h2>
              <div>
                <div>
                  {isHistoryOpen && historyAbsences.map((absence, index) => {
                    const { backgroundColor, icon } = getBackgroundColorWithStatus(absence);
                    const status = absence.deleted === true ? 'Removed' :
                        absence.status === 'Approved'
                          ? 'Past Absence'
                          : 'Declined';

                    const startDate = formatDate(new Date(absence.startDate));
                    const endDate = formatDate(new Date(absence.endDate));
                    return (
                      <div key={index} className='absence-container' title={`${absence.reason}${absence.information === '' ? '' : ' - '}${absence.information}`}>
                        <div
                          className='absence-block'
                          style={{
                            backgroundColor: backgroundColor,
                          }}
                        >
                          <div className='absence-info'>
                            {icon && (
                              <img
                                src={icon}
                                alt={absence.reason}
                                className='absence-icon'
                              />
                            )}
                            <p className='absence-date'>
                              {startDate === endDate ? startDate : `${startDate} - ${endDate}`}
                            </p>
                          </div>
                        </div>
                        <div className='absence-status'>
                          <p className='status' style={{ color: '#929292' }}>{status}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AbsenceFormPopup
        open={absenceFormOpen}
        closePopUp={() => {
          handleAbsenceFormClose();
          fetchPendingRequests();
        }}
      />
    </React.Fragment>
  );
};

export default Profile;
